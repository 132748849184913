<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>AUGUST BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/august-birthstones-peridot-spinel-sardonyx-1280x550.jpg" alt="">
                    <p class="mt-4">
                        Peridot, spinel and sardonyx are the three birthstones for August. The peridot birthstone is known for being formed under extreme conditions, as it can be found in the hardened lava that carried it from deep within Earth’s mantle as well as in meteorites that traveled from outer space. The spinel birthstone was underappreciated until recently, as today’s consumers look for an alternative to ruby, a gem with which red spinel was mistaken for centuries. Sardonyx is the original August birthstone, with a history that dates back more than 4,000 years. Learn more about these three August birthstones and discover the perfect gift for those born in the month of August.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>PERIDOT BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   Peridot is the yellowish green to greenish yellow gem variety of the mineral olivine. Throughout history, peridot has often been confused with other gems such as topaz and emerald. The Red Sea island of Topazios, a purported source of the name “topaz,” actually produced peridot. The Shrine of the Three Holy Kings in Germany’s Cologne Cathedral is decorated with 200 carats of gems that were believed to be emeralds but are, in fact, the August birthstone peridot. Some historians even speculate that Cleopatra’s famous emerald collection may have been comprised of peridot.</p>
                   <p>
 
                The word “peridot” comes from the Arabic faridat, meaning gem. This August birthstone was valued in many ancient and medieval cultures. It appeared in priests’ jewelry as early as the second century BCE and later in the chalices and churches of medieval Europe. The peridot birthstone has also been used for centuries as a protective talisman, shielding the owner from evil spirits and “terrors of the night.”
                
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/61954-peridot-750x750.png" alt="">


            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5 py-5" style="border-top: 1px dotted #ccc;">
            <div class="col-md-10">

                <h1><strong>SPINEL BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   The name “spinel” comes from the Latin word spina, which means thorn, in reference to the shape of spinel crystals. This second August birthstone comes in a wealth of colors: intense red, vibrant pink, orange, purple, violet, blue and bluish green.
                </p>
                <p>
                For centuries, spinel was mistaken for other gemstones. Some of history’s most famous “rubies” have actually turned out to be this August birthstone. The approximately 170 ct Black Prince’s “ruby,” for example, was owned by a succession of Moorish and Spanish kings before Edward, Prince of Wales (also known as the Black Prince) received the stone in 1367 as payment for winning a battle on behalf of Peter of Castile. Not until the 18th century was spinel clearly separated from ruby on the basis of their chemical differences. Today, this historic red spinel is set in Great Britain’s Imperial State Crown, just above the 317.40 ct Cullinan II diamond.
                
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/imperial-state-crown.png" alt="">


            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5 py-5" style="border-top: 1px dotted #ccc;">
            <div class="col-md-10">

                <h1><strong>SARDONYX BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  The most ancient of the August birthstones, sardonyx is a combination of two types of chalcedony (cryptocrystalline quartz): sard and onyx. Bands of brownish red to brown to dark orange sard alternate with typically white or black layers of onyx. In ancient times, sardonyx was a popular stone for Roman seals and signet rings, as hot wax would not stick to it. For millennia, the bands of color in this August birthstone have made it a popular carving material for cameos and intaglios.
                </p>

                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/216960-sardonyx-cameo-750x750.png" alt="">


            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
  name: 'AugustBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },

}
</script>
<style scoped>

</style>

<template>
<div class="text-center">


    <div class="container-fluid" style="background:#efefef">
        <div class="container py-5 text-center">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <h1><strong>OCTOBER BIRTHSTONE</strong></h1>
                     <hr class="mb-5" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                     <img class="img-fluid shadow" src="/img/birthstones/artikel/october-birthstones-opal-tourmaline-1280x550-1.jpg" alt="">
                    <p class="mt-4">
                       Those born in October enjoy two spectacular birthstones to commemorate their birthdays – opal and tourmaline. Both October birthstones have endless color combinations and beautiful coloring characteristics. Learn more about these two October birthstones and discover the perfect gift for those born in the tenth month.
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="container py-5">
        <div class="row d-flex justify-content-center">
            <div class="col-md-10">

                <h1><strong>OPAL BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                   The name of this, the traditional October birthstone, is believed to have originated in India (the source of the first opals brought to the Western world), where in Sanskrit it was called upala, a “precious stone." .” In ancient Rome, this became opalus. Most opals are valued for their shifting colors in rainbow hues – a phenomenon known as “play-of-color.”</p>
                   <p>

                    The October birthstone’s dramatic play-of-color has inspired writers to compare it to fireworks, galaxies and volcanoes. Bedouins  once believed opal held lightning and fell from the sky during thunderstorms. Ancient Greeks thought opals bestowed the gift of prophesy and protection from disease. Europeans long maintained opal to be a symbol of purity, hope and truth. Hundreds of years ago, opal was believed to embody the virtues and powers of all colored stones.</p>
                    <p>

                    Opal is also the stone given to celebrate the 14th wedding anniversary.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/213892-black-opal-900x507.png" alt="">


            </div>
        </div>
        <div class="row d-flex justify-content-center mt-5 py-5" style="border-top: 1px dotted #ccc;">
            <div class="col-md-10">

                <h1><strong>TOURMALINE BIRTHSTONE</strong></h1>
                <hr class="mb-3" style="width:50px; border-bottom:1px solid #666;margin:auto; ">
                <p>
                  Tourmaline is the newer October birthstone. The name comes from the Sinhalese word toramalli, which means “stone with mixed colors,” because it often has multiple colors in one crystal. Very few gems match tourmaline’s dazzling array of colors. Perhaps this is why ancient mystics believed this October birthstone could inspire artistic expression – it has a color palette for every mood. Among the most popular are the pink and red rubellites, the emerald green “chrome” tourmalines, and the neon green and blue-to-violet “paraíba” tourmalines. </p>
                <p>
                Because of its vast range of colors, tourmaline was often mistaken for other gemstones  One of the “rubies” in the Russian crown jewels, the “Caesar’s Ruby” pendant, is actually red (rubellite) tourmaline. A Spanish conquistador found green tourmaline crystals in Brazil in the 1500s and confused the stones with emerald. These and other cases of mistaken identity continued for centuries until scientists recognized tourmaline as a distinct mineral species in the 1800s.
 
                </p>
                <img class="img-fluid shadow my-5" src="/img/birthstones/artikel/11804-tourmaline-multi-colors-900x507.png" alt="">


            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
  name: 'OctoberBirthstone',
  components: {

  },
  data() {
      return {
         
      }
  },

}
</script>
<style scoped>

</style>

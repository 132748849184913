<template>
    <div>   
    <div class="container my-5 text-center">
        <h1 class="mb-4">Custom Jewelry</h1>

        <div>
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col px-0">
                        <img class="img-fluid" src="/img/custom_jewelry/1.png" alt="">
                        </div>
                        <div class="col px-0">
                            <img class="img-fluid" src="/img/custom_jewelry/2.png" alt="">
                        </div>
                        <div class="col px-0">
                            <img class="img-fluid" src="/img/custom_jewelry/3.png" alt="">
                        </div>
                        <div class="col px-0">
                            <img class="img-fluid" src="/img/custom_jewelry/4.png" alt="">
                        </div>
                        <div class="col px-0">
                            <img class="img-fluid" src="/img/custom_jewelry/5.png" alt="">
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="row d-flex justify-content-center mb-5">
                <div class="col-md-5">
                    <p class="mt-5">Get inspired by our custom-made engagement rings, individually designed and crafted by our talented jewellers.</p>
                    <button type="button" @click="customJewelry()" class="btn btn-outline-primary">VIEW CUSTOM GALLERY</button>
                </div>
            </div>

            
        </div>
       

        <div class="my-5">
            <div class="row d-flex justify-content-center">
                <div class="col-md-10">
                    <img class="img-fluid mt-5" src="/img/custom_jewelry/design.png">
                </div>
            </div>
            
            <div class="row d-flex justify-content-center mt-4 mb-5">
                <div class="col-md-8">
                    <h3>Our Custom Design Service</h3>
                    <p class="mt-3">Talk directly with our jewellers and designers, and receive expert advice on how to create your own custom design. Our jewellers can even provide a 3D render of your piece to help you visualise your ideas, so you can be 100% sure you love it before we make it.

                    </p>
                    <a :href="'https://wa.me/628176781986?text=Halo, Saya ingin bertanya tentang custom jewelry'">
                    <button type="button" class="btn btn-outline-primary">TALK TO US</button>
                    </a>
                </div>
            </div>
        </div>

        <div class="row mt-5 pt-5">
            <div class="col-md-4">
                <img class="img-fluid" src="/img/custom_jewelry/superior.png">
                <h3>Superior Quality</h3>
                <p>
                    Your ring needs to last a lifetime. That’s why we never compromise on quality. Benefit from our decades long expertise in crafting custom rings, our strict quality control processes, and our lifetime guarantee.
                </p>
            </div>
            <div class="col-md-4">
                <img class="img-fluid" src="/img/custom_jewelry/superior.png">
                <h3>Metal Jewelleries</h3>
                <p>
                    Your ring needs to last a lifetime. That’s why we never compromise on quality. Benefit from our decades long expertise in crafting custom rings, our strict quality control processes, and our lifetime guarantee.
                </p>
            </div>
            <div class="col-md-4">
                <img class="img-fluid" src="/img/custom_jewelry/superior.png">
                <h3>Personalize Laser Engraving</h3>
                <p>
                    Your ring needs to last a lifetime. That’s why we never compromise on quality. Benefit from our decades long expertise in crafting custom rings, our strict quality control processes, and our lifetime guarantee.
                </p>
            </div>
        </div>

    </div>

        <div class="container-fluid text-center my-5" style="background-color:rgba(204,204,204,0.5);">
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Need Help Designing a Custom Ring?</h1>
                        <p>Not sure where to start?</p>
                        <button class="btn btn-primary mr-2">EMAIL US</button>
                        <button class="btn btn-primary">WHATSAPP</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
  name: 'CustomJewelry',
  components: {

  },
  methods: {
      customJewelry(){
          this.$router.push({path:'/custom-jewelry/design'})
      }
  }
}
</script>